import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import './aboutUs.css';

class AboutUs extends Component{
    render(){
        return(
            <div className="about-container">
                <h1 className="about-header">¿Quienes somos?</h1>
                <Row className="about-us-row">
                    <Col className="about-us-col">
                        <h3>Experiencia</h3>
                        <p>Tenemos más de 30 años de experiencia en el mercado de químicos.</p>
                    </Col>
                    <Col className="about-us-col">
                        <h3>100% Costarricense</h3>
                        <p>Somos una empresa nacional con una amplia gama de de prouctos biodegradables.</p>

                    </Col>
                    <Col className="about-us-col">
                        <h3>Pyme</h3>
                        <p>Siempre nos hemos caracterizado por la excelencia de nuestros productos a precios competitivos.</p>
                    </Col>
                </Row>
                <div className="company-description">
                    <span className="aboutus-description">Contribuimos con el desarrollo de la industria de Costa Rica, llevando productos innovadores y ecológicos. Nos actualizamos constantemente para estar al margen de los nuevos intereses del mercado.</span>
                    <br/>
                    <br/>
                    <span className="aboutus-description">Somos un medio confiable para nuestros clientes porque siempre cumplimos con sus necesidades e intereses por nuestros servicios de calidad.</span>
                </div>
            </div>
        );
    }
}

export default AboutUs;
