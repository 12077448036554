import React, { Component } from 'react'
import { Carousel, CarouselItem } from 'react-bootstrap';
import carouselJson from '../assets/carousel.json';

import './carousel.css';


class CarouselClass extends Component {

    constructor(props) {

        super(props)

        this.state = {
            carouselProducts: [],
        }
    }

    componentWillMount() {
        var jsonProducts = carouselJson;
        var carouselProducts = Object.values(jsonProducts['mainProducts']);

        this.setState({ carouselProducts: [...this.state.carouselProducts, ...carouselProducts] })
    }

    render() {

        return (
            <Carousel>
                {
                    this.state.carouselProducts.map((nombre, index) => {
                        return (
                            <CarouselItem>
                                <img
                                    className="d-block w-100"
                                    src={require('./../assets/carousel/' + nombre.image)}
                                    alt='carousel images'
                                />
                            </CarouselItem>
                        )
                    })
                }
            </Carousel>
        );
    }
}

export default CarouselClass;
