import React, { Component } from 'react';
import { Col, Row} from 'react-bootstrap';
import footerImage from '../assets/quimicosLogo.png';
import './footer.css'


class Footer extends Component{
    
    render(){
        return(
            <footer>
                <Row className="footer-row">
                <Col xs={12} sm={4} className="footer-col">
                    Dirección Física:
                    <br/>
                    La Garita, Alajuela
                    <br/>
                    Teléfonos:
                    <br/>
                    8316-2801 / 2487-9048
                </Col>
                <Col xs={12} sm={4} className="footer-col" >
                    Redes Sociales:
                    <SocialMedia/>
                    <br/>
                    Correo : quimicosqc@gmail.com
                </Col>
                <Col xs={12} sm={4} className="footer-col">
                    <img className="image-footer" src={footerImage} alt="logo"></img>
                </Col>
                </Row>                
            </footer>
        )
    }
}
export default Footer;


class SocialMedia extends Component {
    render() {
      return (
          <div>
             <a className="social-media" href="https://www.facebook.com/quimicosdeloestecr/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
             <a className="social-media" href="https://www.instagram.com/quimicosdeloestecostarica/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          </div>
     
      );
    }
  }