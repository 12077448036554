import React, { Component } from 'react';
import { Navbar, Nav,Image } from 'react-bootstrap';
import logo from '../Quimicos-del-oeste-txt-01.png';
import './menu.css'

class Menu extends Component {

    constructor() {  
        super();      
        this.scrollHandlerProducts = this.scrollHandlerProducts.bind(this);
        this.scrollHandlerAboutUs = this.scrollHandlerAboutUs.bind(this);
        this.scrollHandlerContactUs = this.scrollHandlerContactUs.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        this.collapseMenu = this.collapseMenu.bind(this);
      }

    scrollHandlerProducts(){
        if ( document.getElementsByClassName('products-header').length > 0){
            window.scrollTo(0, document.getElementsByClassName('products-header')[0].offsetTop);
        }
        this.collapseMenu();
    }

    scrollHandlerAboutUs(){
        if ( document.getElementsByClassName('about-container').length > 0){
            window.scrollTo(0, document.getElementsByClassName('about-container')[0].offsetTop);
        }
        this.collapseMenu();
    }

    scrollHandlerContactUs(){
        if ( document.getElementsByClassName('contact-header').length > 0){
            window.scrollTo(0, document.getElementsByClassName('contact-header')[0].offsetTop);
        }
        this.collapseMenu();
    }

    reloadPage(){
        window.location.reload();
    }

    collapseMenu() {
        var menuOptions = document.getElementById("responsive-navbar-nav");
        menuOptions.classList.remove("show");

        var hamburger = document.getElementsByClassName("navbar-toggler")[0];
        hamburger.classList.add("collapsed");
    }

    render() {
        return (
            <div className="nav-bar">
                {/* <img src={logo} className="menu-logo" alt="logo" /> */}
                {/* rg si quiero hamburguesa */}
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
                    <Navbar.Brand onClick={this.reloadPage}>
                        <Image
                            src={logo}
                            width="170"
                            height="60"
                            className="d-inline-block align-top"
                            alt="Quimicos del Oeste Logo"
                        />
                        <span className="slogan">La mejor calidad del mercado</span>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.collapseMenu}/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link onClick={this.scrollHandlerProducts}>Productos</Nav.Link>
                            <Nav.Link onClick={this.scrollHandlerAboutUs}>Acerca de Nosotros</Nav.Link>
                            <Nav.Link onClick={this.scrollHandlerContactUs}>Contacto</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default Menu;
