import React, { Component } from 'react'
import './scrollUp.css';


class ScrollButton extends Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }

    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }


  render() {
    return <button id="backToTop" className='scroll'
      onClick={() => { this.scrollToTop(); }}>
        <i className="fas fa-arrow-circle-up"></i>      
      </button>;
  }
}

class ScrollApp extends Component {

  render() {
    return <div className="long">
      <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
    </div>
  }
}

export default ScrollApp;