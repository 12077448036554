import React, { Component } from 'react';
import whatsapp from './WhatsApp.svg';
import './contact.css'


class Contact extends Component {
  sendWSMessage() {
    var url = 'https://api.whatsapp.com/send?phone=50683162801' ;
    window.open(url);
  }
  render() {
    return (
      <div className="contact-body">
        <h1 className="contact-header">Haga su cotización aquí</h1>

        <img 
        className="whatsappIcon" 
        src={whatsapp} 
        onClick={this.sendWSMessage}
        alt="WhatsApp"></img>
      </div>
    );
  }
}

export default Contact;
