import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import productsJson from '../assets/products.json';
import Category from '../components/category';
import './mainProductsSimplify.css';



class MainProductsSimplify extends Component {


    constructor(props) {

        super(props)
        this.showLessProducts = this.showLessProducts.bind(this);
        this.showMoreProducts = this.showMoreProducts.bind(this);

        this.state = {
            showButtonEnabled: true,
            hideButtonEnabled: false,
            mainProducts: [],
        }
    }

    componentWillMount() {
        var jsonProducts = productsJson;
        var mainProducts = Object.values(jsonProducts['mainProducts']);

        this.setState({ mainProducts: [...this.state.mainProducts, ...mainProducts] })
    }

    showMoreProducts() {
        var elementsContainer = document.getElementsByClassName("hidden-container");
        var elementsTittle = document.getElementsByClassName("hidden-title");

        if (elementsContainer && elementsTittle) {
            for (var i = 0; i < elementsContainer.length; i++) {
                elementsContainer[i].classList.remove("hidden-category");
            }

            for (var j = 0; j < elementsTittle.length; j++) {
                elementsTittle[j].classList.remove("hidden-category");
            }
        }
        this.setState({ showButtonEnabled: false })
        this.setState({ hideButtonEnabled: true })
    }

    showLessProducts() {
        var elementsContainer = document.getElementsByClassName("hidden-container");
        var elementsTittle = document.getElementsByClassName("hidden-title");

        if (elementsContainer && elementsTittle) {
            for (var i = 0; i < elementsContainer.length; i++) {
                elementsContainer[i].classList.add("hidden-category");
            }

            for (var j = 0; j < elementsTittle.length; j++) {
                elementsTittle[j].classList.add("hidden-category");
            }
        }

        this.setState({ showButtonEnabled: true })
        this.setState({ hideButtonEnabled: false })
    }

    render() {
        return (
            <div className="products-section">
                <h1 className="products-header scroll-limit">Conozca nuestros productos</h1>
                        {this.state.mainProducts.map((nombre, index) => {
                            return (

                                <Category key={index}
                                    title={nombre.categoryName}
                                    nameCode={nombre.categoryNameCode}
                                    isVisible={nombre.isVisible}
                                    productsList={nombre.productsList}
                                    right={nombre.right}
                                    image={nombre.image}
                                />
                            )
                        })}
                <Button variant="success"
                    className="show-button"
                    style={this.state.showButtonEnabled ? {} : { display: 'none' }}
                    onClick={this.showMoreProducts} >Ver Más</Button>
                <Button variant="success"
                    className="hide-button"
                    style={this.state.hideButtonEnabled ? {} : { display: 'none' }}
                    onClick={this.showLessProducts} >Ver Menos</Button>

            </div>
        );
    }
}
export default MainProductsSimplify;