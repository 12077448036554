import React, { Component } from 'react';
import Menu from './components/menu'
import CarouselClass from './components/carousel'
// import logo from './logo.svg';
import './App.css';
import AboutUs from './components/aboutUs';
import Footer from './components/footer';
// import MainProducts from './components/mainProducts';
import MainProductsSimplify from './components/mainProductsSimplify';
import Contact from './components/contact'
import ScrollApp from './components/scrollUp';


class App extends Component {

  scrollHandler(){
    if ( document.getElementsByClassName('scroll-limit').length > 0){
        if (window.pageYOffset >= document.getElementsByClassName('scroll-limit')[0].offsetTop){
            document.getElementsByClassName('scroll')[0].style.display = 'block'
        }
        else{
            document.getElementsByClassName('scroll')[0].style.display = 'none'
        }
    }
}

componentDidMount(){
  window.addEventListener('scroll', this.scrollHandler)
}
render() {
  return (
    <div className="App">
      <Menu/>
      <CarouselClass/>
      <MainProductsSimplify/>
      <AboutUs/>
      <Contact/>
      <Footer/>
      <ScrollApp /> 
    </div>
  );
}
}

export default App;
