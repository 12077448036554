import React, { Component } from 'react';
import './mainProductsSimplify.css';


class Category extends Component {

    render() {
        return (
            <div className={this.props.right ? "right-position" : "left-position"}>
                <h4 className={this.props.isVisible ? "category-tittle" : "category-tittle hidden-title hidden-category"}>{this.props.title}</h4>
                <div className={this.props.isVisible ? "products-container" : "products-container hidden-container hidden-category"}>
                <div id={this.props.nameCode + "Image"} className="category-image">
                        <img src={require('./../assets/categories/' + this.props.image)} alt="category"></img>
                </div>
                    <div id={this.props.nameCode} className="product-list" >
                        {this.props.productsList.map((nombre1, index1) => {
                            return (
                                <li key={index1}>{nombre1}</li>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default Category;